<template>
  <div class="my-1">
    <b-table
      :items="chargesList"
      :fields="tableColumns"
      :busy="!chargesList"
      hover
      responsive
      head-variant="info"
      sticky-header
      style="max-height: 65vh"
      thead-class="text-dark align-middle text-nowrap text-center"
      tbody-class="text-dark align-middle text-nowrap text-center"
      class="border-secondary rounded-8px"
      show-empty
      no-border-collapse
      :empty-text="$t('noMatchingResult')"
    >
      <!-- ANCHOR Table Header -->
      <template
        v-for="column in tableColumns"
        v-slot:[`head(${column.key})`]="{ label }"
      >
        <div
          :key="column.label"
          :class="`text-dark text-nowrap ${['netFare', 'tax', 'total'].includes(label) ? 'text-right' : 'text-left'}`"
        >
          {{ $te(`reservation.historyActions.columns.${label}`) ? $t(`reservation.historyActions.columns.${label}`) : label }}
        </div>
      </template>

      <!-- ANCHOR Style for column(s)) -->
      <template #table-colgroup="{ fields }">
        <col
          v-for="field in fields"
          :key="field.key"
          :style="field.style"
        >
      </template>

      <!-- ANCHOR Column orderNo -->
      <!-- <template #cell(orderNo)="{ index }">
          {{ chargesList.length - index }}
        </template> -->

      <!-- ANCHOR Column date -->
      <!-- <template #cell(chargeTime)="{ item }">
          {{ dateTime(item.chargeTime) }}
        </template> -->

      <template #cell(netFare)="{ item }">
        <div class="d-flex-center justify-content-end font-weight-bolder">
          {{ formatCurrency(item.pricing.netFare) }}
        </div>
      </template>
      <template #cell(tax)="{ item }">
        <div class="d-flex-center justify-content-end font-weight-bolder">
          {{ formatCurrency(item.pricing.tax) }}
        </div>
      </template>
      <template #cell(total)="{ item }">
        <div class="d-flex-center justify-content-end font-weight-bolder">
          {{ formatCurrency(item.pricing.total) }}
        </div>
      </template>

      <template #table-busy>
        <div class="d-flex-center text-dark my-2 gap-2">
          <b-spinner class="align-middle" />
          <strong>{{ $t('loading') }}</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable, BSpinner,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import { ref } from '@vue/composition-api'
import sortBy from 'lodash/sortBy'

import {
  dateTime, formatCurrency, convertISODateTime,
} from '@core/utils/filter'

export default {
  components: {
    BTable,
    BSpinner,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const chargesList = ref(sortBy(props.bookingData.charges.map(charge => ({
      ...charge,
      itineraryOrder: charge.segments.map(segmentId => {
        const itinerary = props.bookingData?.itineraries.findIndex(iti => {
          const segment = iti.find(seg => seg.segmentId === segmentId)
          return !!segment
        })
        return itinerary + 1
      }).join(', ') || '',
      paxOrder: props.bookingData.paxLists.findIndex(pax => pax?.paxId === charge.paxId),
    })), [charge => charge.paxOrder, charge => charge.itineraryOrder]))
    const tableColumns = ref([
      // { label: 'orderNo', key: 'orderNo', style: 'width: 3em;' },
      // { label: 'chargeTime', key: 'chargeTime' },
      {
        label: 'segments',
        key: 'segments',
        formatter: (segments, key, item) => item.itineraryOrder || '',
        style: 'width: 3em;',
      },
      {
        label: 'paxId',
        key: 'paxId',
        formatter: (paxId, key, item) => {
          const pax = props.bookingData?.paxLists[Number(item.paxOrder)]
          return `${pax?.title || ''} ${pax?.lastName} ${pax?.firstName}` || ''
        },
        tdClass: 'text-left',
      },
      { label: 'description', key: 'description', tdClass: 'text-left' },
      { label: 'netFare', key: 'netFare' },
      { label: 'tax', key: 'tax' },
      { label: 'total', key: 'total' },
      // { label: 'code', key: 'code' },
    ])
    return {
      tableColumns,
      dateTime,
      formatCurrency,
      convertISODateTime,
      isEmpty,
      chargesList,
    }
  },
}
</script>
<style lang="">

</style>
